import cn from 'classnames';

import type {ImageComponent} from '@/types';
import {AlignmentEnum} from '@/enums';
import {LiHtml} from '@/components/shared/HtmlElements';
import Section from '@/components/base/layouts/Section/Section';
import Image from '@/components/shared/Image/Image';
import type {Mode} from '@/components/base/types';

type Disclaimer = {
  text?: string;
  indicator?: string;
};

interface DisclaimersProps {
  alignment?: AlignmentEnum;
  disclaimers?: Disclaimer[];
  image?: ImageComponent;
  className?: string;
  disclaimerClass?: string;
  listClassName?: string;
  wrapperClassName?: string;
  topSpacing?: '2xl' | '4xl';
  bottomSpacing?: '2xl' | '4xl';
  mode?: Mode;
}

/**
 * Renders disclaimer text, typically at the bottom of a page above the footer.
 * Migrated from brochureV2 without visual modifications.
 */
export default function Disclaimers({
  alignment,
  className,
  disclaimers,
  disclaimerClass,
  image,
  listClassName,
  wrapperClassName,
  topSpacing = '2xl',
  bottomSpacing = '2xl',
  mode = 'dark',
  ...props
}: DisclaimersProps) {
  if (!disclaimers) {
    return null;
  }

  const currentAlignment = alignment || AlignmentEnum.Left;
  const isCenter = currentAlignment === AlignmentEnum.Center;
  const {srcSet, sizes, alt, imageClassName}: any = image || {};
  return (
    <Section
      mode={mode}
      topSpacing={topSpacing}
      bottomSpacing={bottomSpacing}
      sectionName="disclaimers"
      className={className}
      {...props}
    >
      <div className="container">
        <div
          className={cn(
            'flex flex-col',
            {
              'items-start sm:items-center sm:flex-row': !isCenter,
              'items-center': isCenter,
            },
            wrapperClassName,
          )}
        >
          {image && srcSet && (
            <Image
              className={cn(
                'mb-6 h-[190px] w-[190px] object-cover',
                {
                  'sm:mr-6 sm:mb-0': !isCenter,
                },
                imageClassName,
              )}
              srcSet={srcSet}
              sizes={sizes}
              alt={alt}
            />
          )}
          <ul
            className={cn(
              {
                'm-auto text-center': isCenter,
              },
              listClassName,
            )}
          >
            {disclaimers &&
              disclaimers.map(({text, indicator}, index) => {
                return (
                  <LiHtml
                    className={cn(disclaimerClass, 'richtext mb-2')}
                    key={`${index + 1}-disclaimer`}
                  >
                    {indicator
                      ? `<sup style="font-size: 10px" class="align-middle mr-1">${indicator}</sup>${text}`
                      : text}
                  </LiHtml>
                );
              })}
          </ul>
        </div>
      </div>
    </Section>
  );
}
